.navbar {
  height: 80px;
  position: fixed;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  justify-content: space-between;
  z-index: 999;
  background: rgba(255, 254, 254, 0);
  transition-property: background, color;
  transition: background 0.3s ease, color 0.3s ease;
}

.navbar.active {
  border: none;
  background:rgb(255, 255, 255);
  transition-property: background, color;
  transition: background 0.3s ease, color 0.3s ease;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 0 20px;
}

.container {
  max-width: 1300px;
}


.nav-link {
  color: rgb(255, 255, 255);
}
.home .dropdown-toggle::after {
  color: white;
}
.dropdown-toggle::after {
  color: white;
}
.active .dropdown-toggle::after {
  color: black;
}







.flag-image {
  display: flex;
  align-items: center;
}

.flag-image img {
  height: 1rem;
  margin-left: 0px;
}

.navbar-brand {
  color: #4b9bf6;
  text-transform: uppercase;
  font-weight: 600;
  height: auto;
  padding: 0;
  font-size: 22px;
  line-height: 1.6;
}

.navbar-collapse {
  color: white;
  flex-grow: 0;
}

.navbar-collapse .navbar-nav {
  font-size: 15px;
  line-height: 20px;
}

.navbar-collapse a:last-child {
  padding-right: 0;
}

.navbar-collapse a {
  position: relative;
}

.navbar-collapse a:hover,
.navbar-collapse a.active {
  background: none;
  color: #4b9bf6;
}

.navbar-collapse a:hover:before,
.navbar-collapse a.active:before {
  right: 7px;
  left: 8px;
  opacity: 1;
}

.navbar-collapse a:before {
  content: '';
  background: none;
  height: 1px;
  position: absolute;
  bottom: 7px;
  left: -20px;
  right: 100%;
  opacity: 0;
}

.logo-container {
  /* Make the image width 100% of its container */
  height: auto;
  max-width: 250px; /* Optionally set a maximum width for larger screens */
  object-fit: scale-down;
}
/* Remove logo max-width, height, and object-fit */

/* For the default (or other) pages */

/* Add margin between the flag and other elements */

@media only screen and (max-width: 1023px) {
  .logo-change {
    content: url('../assets/images/logo1.png');
  }
  .navbar {
      padding: 10px;
      margin: auto;
      background: rgb(255, 255, 255);
      transition-property: background, color;
      transition: background 0.3s ease, color 0.3s ease;
  }

  .container {
      max-width: 100%; /* Adjust as needed for smaller screens */
      color: black;
      background-color:rgb(255, 255, 255);
  }

  .nav-link {
      color: rgb(0, 0, 0);
  }
  .navbar-nav .dropdown-menu {
    position: static;
    background-color:rgb(255, 255, 255);
  }

  .navbar-collapse .navbar-nav {
      float: none;
  }

  .navbar-collapse .nav-item {
      float: none;
      display: block;
      background-color:rgb(255, 255, 255);
  }
}

@media only screen and (max-width: 768px) {
  .logo-change {
    content: url('../assets/images/logo1.png');
  }
  .container {
      max-width: 100%; /* Adjust as needed for smaller screens */
      color: black;
      background-color:rgb(255, 255, 255);
  }

  .navbar-nav .nav-link,
  .home .dropdown-toggle::after {
    color: black !important;
  }
  .dropdown-title {
    color: black !important;
  }
  .border-white {
    border-color: black !important;
  }
  .border-black {
    border-color: black !important;
  }
  .hover\\:border-black {
    border-color: black !important;
  }
  .navbar-nav .dropdown-menu {
    position: static;
    background-color:rgb(255, 255, 255);
  }

  .navbar-collapse .navbar-nav {
      float: none;
  }

  .navbar-collapse .nav-item {
      float: none;
      display: block;
      background-color:rgb(255, 255, 255);
  }
  .logo-container {
    /* Make the image width 100% of its container */
    height: auto;
    max-width: 220px; /* Optionally set a maximum width for larger screens */
    object-fit: scale-down;
  }

}
