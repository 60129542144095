.footer {
  max-width: 1300px;
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  width: 100%; /* Set the width to 100% */
}

.max-w-screen-xl {
  max-width: 100%;
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns for left, center, and right sections */
  grid-gap: 20px; /* Spacing between grid items */
  max-width: 1400px; /* Adjust the maximum width as needed */
  margin: 0 auto; /* Center the grid horizontally */
  text-align: center;
}

.footer-left,
.footer-center,
.footer-right {
  padding: 20px;
  border: 1px solid #f9f5f500; /* Optional border for grid items */
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.footer-left h2,
.footer-center h2,
.footer-right h2 {
  font-size: 28px;
}

.footer-left-bottom,
.footer-center-bottom,
.footer-right-bottom {
  margin-top: 20px;
}

.button-link {
  background-color: #007bff00;
  color: #fff;
  border: solid 1px #fcfcfd;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 9999px;
  text-decoration: none; /* Remove default link underline */
  transition: background-color 0.3s, color 0.3s; /* Add transition effect */
}

.button-link:hover {
  color: #007bff; /* Change text color on hover */
  border-color: #007bff;
}

.footer-left-bottom a,
.footer-center-bottom a,
.footer-right-bottom a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
}

.footer-left-bottom a:hover,
.footer-center-bottom a:hover,
.footer-right-bottom a:hover {
  color: #007bff;
}

/* Media query for screens up to 767px */
@media only screen and (max-width: 767px) {
  .footer-grid {
    grid-template-columns: 1fr; /* Single column layout for small screens */
  }

  .footer-left,
  .footer-center,
  .footer-right {
    border: none; /* Remove the border for small screens */
  }

  .footer-left h2,
  .footer-center h2,
  .footer-right h2 {
    font-size: 18px; /* Decrease font size for small screens */
  }

  .footer-right-top button {
    padding: 8px 16px; /* Adjust button padding for small screens */
  }
}
