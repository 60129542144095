


.img {
  max-width: 100%;
  height: auto;
  padding: 0px;
}

.table-container {
  width: auto;
  height: auto;
  padding: 0px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

table {
  border: 2px solid rgba(32, 48, 113, 0);
  max-width: 500px; /* Adjust the width as needed */
  width: 100%; /* Take full width of the container */
  height: auto; /* Automatically adjust height based on content */
  margin: 20px 0; /* Add margin as needed */
}

th,
td {
  border: 1px solid black; /* Add borders to table cells */
  padding: 5px; /* Add padding for spacing */
  text-align: center;
}

.horizontal-table {
  width: 100%;
  border-collapse: collapse;
}

.horizontal-table th, .horizontal-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}


.article-container img {
  display: block; /* Make the image a block-level element */
  margin: 0 auto; /* Center horizontally using auto margins */
  max-width: 100%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}


.optimization-criteria {
  list-style: decimal;
  margin-left: 1.5rem; /* Adjust the left margin as needed */
  padding-left: 1rem;
    
}


  
/* Styling for the individual criteria items */
.optimization-criteria li {
  margin-bottom: 1rem; /* Adjust the margin between list items as needed */
  font-weight: 0;
}


/* Text color styles for optimization criteria */
.optimization-criteria li .text-blue-400 {
  color: blue; /* Blue color */
}

.optimization-criteria li .text-red-400 {
  color: #e74c3c; /* Red color */
}

.optimization-criteria li .text-green-400 {
  color: green; /* Green color */
}


.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99/var(--tw-text-opacity));
}

.container-bar-line {
  display: flex; /* Use flexbox to align items in a row */
  align-items: center; /* Vertically center the items */
}
.blue-bar {
  height: 5px; /* Adjust the height of the red bar as needed */
  background-color: blue;
  width: 50px; /* Adjust the width of the red bar as needed */
  margin-right: 5px;
}

.text-blue-400 {
  color: blue; /* Blue color */
}
.red-bar {
  height: 5px; /* Adjust the height of the red bar as needed */
  background-color: red;
  width: 50px; /* Adjust the width of the red bar as needed */
  margin-right: 5px;
}

.text-red-400 {
  color: #e74c3c; /* Blue color */
}

.green-bar {
  height: 5px; /* Adjust the height of the red bar as needed */
  background-color: green;
  width: 50px; /* Adjust the width of the red bar as needed */
  margin-right: 5px;
}

.text-green-400 {
  color: green; /* Blue color */
}
.yellow-bar {
  height: 5px; /* Adjust the height of the red bar as needed */
  background-color: rgb(243, 243, 22);
  width: 50px; /* Adjust the width of the red bar as needed */
  margin-right: 5px;
}
.text-yellow-400 {
  color: rgb(206, 206, 6); /* Blue color */
}


/* Add specific styles for the previous and next buttons */
.button-container {
  max-width: 1300px; /* Adjust max-width as needed */
  margin-left: auto;
  margin-right: auto;
  padding: 15px; /* Adjust horizontal padding as needed */
}

/* Default styles for larger screens (3x11 table) */












/* Media query for screens with a maximum width of 768px */
@media only screen and (max-width: 768px) {
  .article-container {
    padding: 5px; /* Adjust padding for smaller screens */
  }

  .content-container {
    flex-direction: column; /* Stack content in a column for smaller screens */
  }

  .left-content,
  .right-content {
    width: 100%; /* Full width for content on smaller screens */
    padding: 5px; /* Adjust padding for smaller screens */
  }
  .table-container {
    width: 100%; /* Full width for smaller screens */
    margin: auto;
    padding: 0%;
  }

  table {
    max-width: auto; /* Remove max-width for smaller screens */
  }
  th, td {
    padding: 3px; /* Adjust padding for smaller screens */
  }
  

  .prev-button,
  .next-button {
    width: 50%; /* Adjust button width for smaller screens to fit in the same line */
  }
}






