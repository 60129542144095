
.fdc-content-layout {
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow: hidden
}

.fdc-content-layout>section {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 8rem;
  padding-bottom: 4rem;
  
}




